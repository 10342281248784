import React from 'react'
import "./../TopNavbar/TopNavbarStyle.css"
function TopeNavbar() {
  return (
    <div className='main_div_top_navbar'>
      <p className='sign_in_or_register_text'> Sign in or Register</p>
    </div>
  )
}

export default TopeNavbar
