import React from 'react'
import TopeNavbar from '../TopNavbar/TopeNavbar'
import Navbar from '../Navbar/Navbar'
import FirstNavbar from '../FirstNavbar/FirstNavbar'
import Signin from '../SignIn/Signin'
import Popularshop from '../Popularshop/Popularshop'

function ComponentMerge() {
  return (
    <>
       <TopeNavbar/>
     <Navbar/>
     <FirstNavbar/>
    <Signin/>
    <Popularshop/>
    </>
  )
}

export default ComponentMerge
