import React from 'react'
import "./../FirstNavbar/FirstNavbarStyle.css"
function FirstNavbar() {
  return (
    <div className='main_div_second_navbar'>
     <p className='sign_in_text'>Sign in</p>
    </div>
  )
}

export default FirstNavbar
