import React from "react";
import "./../Popularshop/Popularshop.css";
import house from "../../Assets/house-logo.png";
import signal from "../../Assets/signal-img.png";
import contect from "../../Assets/contect-img-o2.png";
import track from "../../Assets/terack-img.png";
import search from "../../Assets/search-img-o2.png";
import facebook from "../../Assets/facebook-img.png";
import youtube from "../../Assets/youtube@2x_0.png";
import twitter from "../../Assets/twitter@2x_0.png";
import instagram from "../../Assets/instagram@2x.png";
function Popularshop() {
  return (
    <div className="main_dive_popularshop">
      <div className="row p-0 m-0">
        <div className="col-lg-2">t</div>
        <div className="col-lg-3">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-3 mt-4">
              <img className="home_logo" src={house} alt="house_img" />
            </div>
            <div className="col-lg-9 col-md-9 col-9 input_text_homelogo mt-4">
              {" "}
              Find a store
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-3  mt-4">
              <img className="home_logo" src={signal} alt="house_img" />
            </div>
            <div className="col-lg-9 col-md-9 col-9 input_text_homelogo mt-4">
              {" "}
              Check our network
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-3  mt-4">
              <img className="home_logo" src={contect} alt="house_img" />
            </div>
            <div className="col-lg-9 col-md-9 col-9 input_text_homelogo mt-4">
              {" "}
              Sign in to My O2
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-3  mt-4">
              <img className="home_logo" src={track} alt="house_img" />
            </div>
            <div className="col-lg-9 col-md-9 col-9 input_text_homelogo mt-4">
              {" "}
              Track my order
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-3  mt-4">
              <img className="home_logo" src={search} alt="house_img" />
            </div>
            <div className="col-lg-9 col-md-9 col-9 input_text_homelogo mt-4"> Search</div>
          </div>
          <hr className="white_line" />
        </div>

        <div className="col-lg-2 ">
          <p className="populer_shop_heading_ mt-4">Popular in shop</p>
          <p className="populer_shop_heading_text  ">iPhone 15 Pro Max</p>
          <p className="populer_shop_heading_text">iPhone 15 Pro</p>
          <p className="populer_shop_heading_text">iPhone 15</p>
          <p className="populer_shop_heading_text">Apple Watch Series 9</p>
          <p className="populer_shop_heading_text">Apple Watch Ultra 2</p>
          <p className="populer_shop_heading_text">Samsung Galaxy S23 Ultra</p>
          <p className="populer_shop_heading_text">Samsung Galaxy S23</p>
          <p className="populer_shop_heading_text">Samsung Galaxy Z Flip5</p>
          <p className="populer_shop_heading_text">Google Pixel 8 Pro</p>
          <p className="populer_shop_heading_text">Sim only</p>
          <p className="populer_shop_heading_text">
            Virgin Media broadband deals
          </p>

          <hr className="white_line" />
        </div>
        <div className="col-lg-4">
          <p className="help_heading mt-4">Help and support</p>
          <p className="help_heading_text">Help home</p>
          <p className="help_heading_text">Contact us</p>
          <p className="help_heading_text">My O2</p>
          <p className="help_heading_text">Collection and delivery</p>
          <hr className="white_line_help" />

          <p className="shop_heading mt-4">Shop</p>
          <p className="help_heading_text">Phones</p>
          <p className="help_heading_text">Tablets</p>
          <p className="help_heading_text">Pay Monthly Sim</p>
          <p className="help_heading_text">Pay As You Go Sim</p>
          <p className="help_heading_text">Virgin Media 02</p>
          <p className="help_heading_text">Group Venture</p>
        </div>
      </div>

      <div className="row p-0 m-0">
        <div className="col-lg-3"></div>
        <div className="col-lg-2 col-md-12 col-12">
          <div className="row">
            <div className="col-lg-2 col-md-1 col-2">
              <img className="facebook_logo" src={facebook} alt="house_img" />
            </div>
            <div className="col-lg-2 col-md-1 col-2">
              <img className="facebook_logo" src={youtube} alt="house_img" />
            </div>
            <div className="col-lg-2 col-md-1 col-2">
              <img className="facebook_logo" src={twitter} alt="house_img" />
            </div>
            <div className="col-lg-2 col-md-1 col-2">
              <img className="facebook_logo" src={instagram} alt="house_img" />
            </div>
          </div>
        </div>
      </div>

      <div className="container ">
        <p className="twitter_paragraf1">About 02, Better Connections Plan, Careers, News & PR, Sponsorship, Terms & Conditions, Accessibility, Privacy, Cookie, and Modern Slavery Statement © 2023, Virgin Media and O2 Personal Legal Menu The Telef0nica UK Company <br/>Telef0nica UK Limited is governed and authorized by the Financial Conduct Authority with regard to consumer credit (Reference Number 715812). </p>
      </div>
    </div>
  );
}

export default Popularshop;
