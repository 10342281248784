import "./App.css";
import FirstNavbar from "./Camponents/FirstNavbar/FirstNavbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./Camponents/Navbar/Navbar";
import Signin from "./Camponents/SignIn/Signin";
import TopeNavbar from "./Camponents/TopNavbar/TopeNavbar";
import Popularshop from "./Camponents/Popularshop/Popularshop";
import ComponentMerge from "./Camponents/ComponentMerge/ComponentMerge";
function App() {
  return (
    <>
      <Router>
        <Routes>
        <Route exact path="/" element={<ComponentMerge />} />  
         
          <Route path="https://www.o2.co.uk/" />
        </Routes>
      </Router>
    </>
  );
}

export default App;
